import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";
import { cryptos } from "utilities/wallets";

const RestoreMissedTransaction: React.FC = () => {
  const [coin, setCrypto] = useState<string>("");
  const [hash, setHash] = useState<string>("");

  const {
    execute: restoreMissedHash,
    status,
    value,
  } = useAsync(
    () =>
      post(`transactions/queryMissedPublic`, {
        crypto: coin,
        hash,
      }),
    false
  );

  const handleSubmit = () => {
    if (!coin) return showDanger("Select a crypto");
    if (!hash || hash.length < 3) return showDanger("Enter valid hash");
    restoreMissedHash();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Transaction restored successfully");
      setCrypto("");
      setHash("");
    }
  }, [value]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <h2 className="text-xl">Restore Missed Transaction</h2>
        <div className="flex gap-8 mt-14">
          <div className="w-6/12">
            <div className="relative mb-5 md:w-10/12">
              <label>Crypto</label>
              <select
                className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                value={coin}
                onChange={(e) => setCrypto(e.target.value)}
              >
                <option value="">Select Crypto</option>
                {cryptos.map((crypto) => (
                  <option key={crypto} value={crypto}>
                    {crypto.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative mb-5 md:w-10/12">
              <label>Enter Missed Hash</label>
              <input
                type="text"
                value={hash}
                onChange={(e) => setHash(e.target.value)}
                className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
              />
            </div>

            <div className="text-center">
              <VButton
                status={status}
                onClick={handleSubmit}
                className="w-4/12 px-20 py-3 mt-5 font-light text-white bg-black rounded-lg"
              >
                Restore Hash
              </VButton>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default RestoreMissedTransaction;
