import useGetAdmin from "hooks/useGetAdmin";
import useGetSubdomain from "hooks/useGetSubdomain";
import useGetSupport from "hooks/useGetSupport";
import useGetVendor from "hooks/useGetVendor";
import {
  Navigate,
  Routes as ReactRoutes,
  Route,
  useLocation,
} from "react-router-dom";

import {
  adminRoutes,
  publicRoutes,
  subdomainRoutes,
  supportRoutes,
  vendorRoutes,
} from "routes/routes";
import About from "screens/About";
import Home from "screens/Home";
import Refer from "screens/Refer";
import ResetUserPassword from "screens/ResetPassword";
import Verify from "screens/Verify";
import Acquisitions from "screens/__ADMIN__/analytics/Acquisitions";
import RevenueAnalytics from "screens/__ADMIN__/analytics/Revenue";
import TransactionAnalytics from "screens/__ADMIN__/analytics/TransactionAnalytics";
import AdminLogin from "screens/__ADMIN__/auth/Login";
import AdminRegister from "screens/__ADMIN__/auth/Register";
import AdminDashboard from "screens/__ADMIN__/dashboard";
import Balances from "screens/__ADMIN__/dashboard/Balances";
import CreditUser from "screens/__ADMIN__/dashboard/CreditUser";
import NotifyAll from "screens/__ADMIN__/dashboard/NotifyAll";
import Rates from "screens/__ADMIN__/dashboard/Rates";
import TestSms from "screens/__ADMIN__/dashboard/TestSms";
import AdminDeposits from "screens/__ADMIN__/deposits";
import DepositInfo from "screens/__ADMIN__/deposits/Info";
import Merchants from "screens/__ADMIN__/merchants";
import AssignVendors from "screens/__ADMIN__/merchants/AssignVendors";
import MerchantAnalytics from "screens/__ADMIN__/merchants/MerchantAnalytics";
import MerchantLimit from "screens/__ADMIN__/merchants/MerchantLimit";
import MerchantTransactions from "screens/__ADMIN__/merchants/MerchantTransactions";
import Transactions from "screens/__ADMIN__/transactions";
import Users from "screens/__ADMIN__/users";
import AddressesExplorer from "screens/__ADMIN__/users/AddressesExplorer";
import UserAnalytics from "screens/__ADMIN__/users/UserAnalytics";
import UserBalances from "screens/__ADMIN__/users/UserBalances";
import UserTransactions from "screens/__ADMIN__/users/UserTransactions";
import VendorLogin from "screens/__VENDOR__/auth/Login";
import VendorRegister from "screens/__VENDOR__/auth/Register";
import VendorDashboard from "screens/__VENDOR__/dashboard";
import Deposit from "screens/__VENDOR__/deposits";
import DepositItem from "screens/__VENDOR__/deposits/DepositItem";
import PaymentMethods from "screens/__VENDOR__/paymentMethods";
import AddBank from "screens/__VENDOR__/paymentMethods/AddBank";
import SelectBank from "screens/__VENDOR__/paymentMethods/SelectBank";
import VerifyAdd from "screens/__VENDOR__/paymentMethods/VerifyAdd";
import Profile from "screens/__VENDOR__/profile";
import ChangePIN from "screens/__VENDOR__/profile/ChangePIN";
import CreatePIN from "screens/__VENDOR__/profile/CreatePIN";
import ResetPassword from "screens/__VENDOR__/profile/ResetPassword";
import Withdraw from "screens/__VENDOR__/withdraw";
import ConfirmWithdraw from "screens/__VENDOR__/withdraw/ConfirmWithdraw";
import EnterAmount from "screens/__VENDOR__/withdraw/EnterAmount";
import Withdrawals from "screens/__VENDOR__/withdrawals";
import WithdrawalList from "screens/__VENDOR__/withdrawals/WithdrawalList";
import WithdrawalItem from "screens/__VENDOR__/withdrawals/withdrawalItem";
import Helpcenter from "screens/helpcenter";
import Privacy from "screens/static/Privacy";
import Terms from "screens/static/Terms";
import External from "screens/trades/External";
import Recurring from "screens/trades/Recurring";

import MobileVerify from "screens/MobileVerify";
import FrozenTronAccounts from "screens/__ADMIN__/analytics/FrozenTronAccounts";
import GrowthMetrics from "screens/__ADMIN__/analytics/GrowthMetrics";
import BtcBalance from "screens/__ADMIN__/dashboard/BtcBalance";
import PaymentMerchant from "screens/__ADMIN__/dashboard/PaymentMerchant";
import { AdminMarket } from "screens/__ADMIN__/transactions/AdminMarket";
import RestoreMissedTransaction from "screens/__ADMIN__/transactions/RestoreMissedTransaction";
import Vouchers from "screens/__ADMIN__/transactions/Vouchers";
import SupportLogin from "screens/__SUPPORT__/auth/Login";
import SupportRegister from "screens/__SUPPORT__/auth/Register";
import SupportTransactions from "screens/__SUPPORT__/transactions";
import SupportVouchers from "screens/__SUPPORT__/transactions/Vouchers";
import SupportUsers from "screens/__SUPPORT__/users";
import SupportAddressesExplorer from "screens/__SUPPORT__/users/AddressesExplorer";
import SupportUserAnalytics from "screens/__SUPPORT__/users/UserAnalytics";
import SupportUserBalances from "screens/__SUPPORT__/users/UserBalances";
import SupportUserTransactions from "screens/__SUPPORT__/users/UserTransactions";
import SupportDashboard from "screens/__SUPPORT__/users/index";
import ConfirmDeleteAccount from "screens/delete-account/ConfirmDeleteAccount";
import RequestDeleteAccount from "screens/delete-account/RequestDeleteAccount";

export function RequireAuth({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { vendor } = useGetVendor();

  const location = useLocation();

  if (!vendor?.id) {
    return <Navigate to={publicRoutes.login} state={{ from: location }} />;
  }

  return children;
}

export function RequireAuthAdmin({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { admin } = useGetAdmin();

  const location = useLocation();

  if (!admin?.id) {
    return <Navigate to={publicRoutes.register} state={{ from: location }} />;
  }

  return children;
}

export function RequireAuthSupport({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { support } = useGetSupport();

  const location = useLocation();

  if (!support?.id) {
    return <Navigate to={publicRoutes.register} state={{ from: location }} />;
  }

  return children;
}

const mainRoutes = (
  <>
    <Route path={publicRoutes.home} element={<Home />} />
    <Route path={publicRoutes.recurringTrade} element={<Recurring />} />
    <Route path={publicRoutes.externalTrade} element={<External />} />
    <Route path={publicRoutes.helpcenter}>
      <Route path={""} element={<Helpcenter />} />
      <Route path={":help"} element={<Helpcenter />} />
    </Route>
    <Route path={publicRoutes.refer}>
      <Route path={""} element={<Refer />} />
      <Route path={":user"} element={<Refer />} />
    </Route>
    <Route path={publicRoutes.verify}>
      <Route path={""} element={<Verify />} />
      <Route path={":user"} element={<Verify />} />
    </Route>
    <Route path={publicRoutes.mobileVerify}>
      <Route path={""} element={<MobileVerify />} />
      <Route path={":user"} element={<MobileVerify />} />
    </Route>
    <Route path={publicRoutes.privacyPolicy} element={<Privacy />} />
    <Route path={publicRoutes.terms} element={<Terms />} />
    <Route path={publicRoutes.about} element={<About />} />
    <Route path={publicRoutes.resetPassword} element={<ResetUserPassword />} />
    <Route
      path={publicRoutes.requestAccountDeletion}
      element={<RequestDeleteAccount />}
    />
    <Route
      path={publicRoutes.confirmAccountDeletion}
      element={<ConfirmDeleteAccount />}
    />
    <Route path={"*"} element={<Home />} />
  </>
);

const Switch = () => {
  let { subdomain } = useGetSubdomain();
  switch (subdomain) {
    case subdomainRoutes.default:
      return mainRoutes;
    case subdomainRoutes.www:
      return mainRoutes;

    case subdomainRoutes.admin:
      return (
        <>
          <Route path={adminRoutes.login} element={<AdminLogin />} />
          <Route path={adminRoutes.register} element={<AdminRegister />} />

          <Route
            path={adminRoutes.dashboard}
            element={
              <RequireAuthAdmin>
                <AdminDashboard />
              </RequireAuthAdmin>
            }
          />
          <Route path={adminRoutes.balances}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <Balances />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"user/:user"}
              element={
                <RequireAuthAdmin>
                  <UserBalances />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={":type"}
              element={
                <RequireAuthAdmin>
                  <Balances />
                </RequireAuthAdmin>
              }
            />
          </Route>
          <Route path={adminRoutes.explorer}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <AddressesExplorer />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"user/:user"}
              element={
                <RequireAuthAdmin>
                  <AddressesExplorer />
                </RequireAuthAdmin>
              }
            />
          </Route>
          <Route path={adminRoutes.transactions}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <Transactions />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"user/:user"}
              element={
                <RequireAuthAdmin>
                  <UserTransactions />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"vouchers"}
              element={
                <RequireAuthAdmin>
                  <Vouchers />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"admin-market"}
              element={
                <RequireAuthAdmin>
                  <AdminMarket />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"missed-transaction"}
              element={
                <RequireAuthAdmin>
                  <RestoreMissedTransaction />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={":type"}
              element={
                <RequireAuthAdmin>
                  <Transactions />
                </RequireAuthAdmin>
              }
            />
          </Route>
          <Route path={adminRoutes.users}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <Users />
                </RequireAuthAdmin>
              }
            />
            <Route path={":user"}>
              <Route
                path={""}
                element={
                  <RequireAuthAdmin>
                    <Users />
                  </RequireAuthAdmin>
                }
              />
            </Route>
          </Route>

          <Route path={adminRoutes.merchants}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <Merchants />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"assign"}
              element={
                <RequireAuthAdmin>
                  <AssignVendors />
                </RequireAuthAdmin>
              }
            />
            <Route path={":user"}>
              <Route
                path={""}
                element={
                  <RequireAuthAdmin>
                    <Merchants />
                  </RequireAuthAdmin>
                }
              />

              <Route
                path={"limit"}
                element={
                  <RequireAuthAdmin>
                    <MerchantLimit />
                  </RequireAuthAdmin>
                }
              />
              <Route
                path={"transactions"}
                element={
                  <RequireAuthAdmin>
                    <MerchantTransactions />
                  </RequireAuthAdmin>
                }
              />
              <Route
                path={"analytics"}
                element={
                  <RequireAuthAdmin>
                    <MerchantAnalytics />
                  </RequireAuthAdmin>
                }
              />
            </Route>
          </Route>

          <Route path={adminRoutes.deposits}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <AdminDeposits />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"info/:id"}
              element={
                <RequireAuthAdmin>
                  <DepositInfo />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={":type"}
              element={
                <RequireAuthAdmin>
                  <AdminDeposits />
                </RequireAuthAdmin>
              }
            />
          </Route>

          <Route path={adminRoutes.analytics}>
            <Route
              path={""}
              element={
                <RequireAuthAdmin>
                  <RevenueAnalytics />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"user/:user"}
              element={
                <RequireAuthAdmin>
                  <UserAnalytics />
                </RequireAuthAdmin>
              }
            />

            <Route
              path={"transactions"}
              element={
                <RequireAuthAdmin>
                  <TransactionAnalytics />
                </RequireAuthAdmin>
              }
            />
            <Route
              path={"acquisitions"}
              element={
                <RequireAuthAdmin>
                  <Acquisitions />
                </RequireAuthAdmin>
              }
            />

            <Route
              path={"growth-metrics"}
              element={
                <RequireAuthAdmin>
                  <GrowthMetrics />
                </RequireAuthAdmin>
              }
            />

            <Route
              path={"revenue"}
              element={
                <RequireAuthAdmin>
                  <RevenueAnalytics />
                </RequireAuthAdmin>
              }
            />
          </Route>

          <Route
            path={adminRoutes.rates}
            element={
              <RequireAuthAdmin>
                <Rates />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.creditUser}
            element={
              <RequireAuthAdmin>
                <CreditUser />
              </RequireAuthAdmin>
            }
          />

          <Route
            path={adminRoutes.testSms}
            element={
              <RequireAuthAdmin>
                <TestSms />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.notifyAll}
            element={
              <RequireAuthAdmin>
                <NotifyAll />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.paymentMerchant}
            element={
              <RequireAuthAdmin>
                <PaymentMerchant />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.btcBalance}
            element={
              <RequireAuthAdmin>
                <BtcBalance />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.frozenTronAccounts}
            element={
              <RequireAuthAdmin>
                <FrozenTronAccounts />
              </RequireAuthAdmin>
            }
          />

          <Route path={"*"} element={<AdminLogin />} />
        </>
      );
    case subdomainRoutes.vendor:
      return (
        <>
          <Route path={vendorRoutes.login} element={<VendorLogin />} />
          <Route path={vendorRoutes.register} element={<VendorRegister />} />

          <Route
            path={vendorRoutes.dashboard}
            element={
              <RequireAuth>
                <VendorDashboard />
              </RequireAuth>
            }
          />
          <Route path={vendorRoutes.withdrawals}>
            <Route
              path={""}
              element={
                <RequireAuth>
                  <Withdrawals />
                </RequireAuth>
              }
            />
            <Route
              path={"transaction/:item"}
              element={
                <RequireAuth>
                  <WithdrawalItem />
                </RequireAuth>
              }
            />
            <Route
              path={":list"}
              element={
                <RequireAuth>
                  <WithdrawalList />
                </RequireAuth>
              }
            />
          </Route>

          <Route path={vendorRoutes.deposits}>
            <Route
              path={""}
              element={
                <RequireAuth>
                  <Deposit />
                </RequireAuth>
              }
            />
            <Route
              path={"transaction/:item"}
              element={
                <RequireAuth>
                  <DepositItem />
                </RequireAuth>
              }
            />
          </Route>

          <Route path={vendorRoutes.withdraw}>
            <Route
              path={""}
              element={
                <RequireAuth>
                  <Withdraw />
                </RequireAuth>
              }
            />
            <Route
              path={"amount"}
              element={
                <RequireAuth>
                  <EnterAmount />
                </RequireAuth>
              }
            />
            <Route
              path={"confirm"}
              element={
                <RequireAuth>
                  <ConfirmWithdraw />
                </RequireAuth>
              }
            />
          </Route>
          <Route path={vendorRoutes.paymentMethods}>
            <Route
              path={""}
              element={
                <RequireAuth>
                  <PaymentMethods />
                </RequireAuth>
              }
            />
            <Route
              path={"add"}
              element={
                <RequireAuth>
                  <AddBank />
                </RequireAuth>
              }
            />
            <Route
              path={"verify-add"}
              element={
                <RequireAuth>
                  <VerifyAdd />
                </RequireAuth>
              }
            />
            <Route
              path={"transaction/:item"}
              element={
                <RequireAuth>
                  <WithdrawalItem />
                </RequireAuth>
              }
            />
            <Route
              path={":type"}
              element={
                <RequireAuth>
                  <SelectBank />
                </RequireAuth>
              }
            />
          </Route>

          <Route path={vendorRoutes.profile}>
            <Route
              path={""}
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path={"reset-password"}
              element={
                <RequireAuth>
                  <ResetPassword />
                </RequireAuth>
              }
            />
            <Route
              path={"change-pin"}
              element={
                <RequireAuth>
                  <ChangePIN />
                </RequireAuth>
              }
            />
            <Route
              path={"create-pin"}
              element={
                <RequireAuth>
                  <CreatePIN />
                </RequireAuth>
              }
            />
          </Route>

          <Route path={"*"} element={<VendorLogin />} />
        </>
      );

    case subdomainRoutes.support:
      return (
        <>
          <Route path={supportRoutes.login} element={<SupportLogin />} />
          <Route path={supportRoutes.register} element={<SupportRegister />} />

          <Route
            path={supportRoutes.dashboard}
            element={
              <RequireAuthSupport>
                <SupportDashboard />
              </RequireAuthSupport>
            }
          />
          <Route path={supportRoutes.balances}>
            <Route
              path={"user/:user"}
              element={
                <RequireAuthSupport>
                  <SupportUserBalances />
                </RequireAuthSupport>
              }
            />
          </Route>
          <Route path={supportRoutes.explorer}>
            <Route
              path={""}
              element={
                <RequireAuthSupport>
                  <SupportAddressesExplorer />
                </RequireAuthSupport>
              }
            />
            <Route
              path={"user/:user"}
              element={
                <RequireAuthSupport>
                  <SupportAddressesExplorer />
                </RequireAuthSupport>
              }
            />
          </Route>
          <Route path={supportRoutes.transactions}>
            <Route
              path={""}
              element={
                <RequireAuthSupport>
                  <SupportTransactions />
                </RequireAuthSupport>
              }
            />
            <Route
              path={"user/:user"}
              element={
                <RequireAuthSupport>
                  <SupportUserTransactions />
                </RequireAuthSupport>
              }
            />
            <Route
              path={"vouchers"}
              element={
                <RequireAuthSupport>
                  <SupportVouchers />
                </RequireAuthSupport>
              }
            />
            <Route
              path={":type"}
              element={
                <RequireAuthSupport>
                  <SupportTransactions />
                </RequireAuthSupport>
              }
            />
          </Route>
          <Route path={supportRoutes.users}>
            <Route
              path={""}
              element={
                <RequireAuthSupport>
                  <SupportUsers />
                </RequireAuthSupport>
              }
            />
            <Route path={":user"}>
              <Route
                path={""}
                element={
                  <RequireAuthSupport>
                    <SupportUsers />
                  </RequireAuthSupport>
                }
              />
            </Route>
          </Route>

          <Route path={supportRoutes.analytics}>
            <Route
              path={"user/:user"}
              element={
                <RequireAuthSupport>
                  <SupportUserAnalytics />
                </RequireAuthSupport>
              }
            />
          </Route>

          <Route path={"*"} element={<SupportLogin />} />
        </>
      );

    default:
      return <Route path={"*"} element={<Home />} />;
  }
};

function Routes() {
  return <ReactRoutes>{Switch()}</ReactRoutes>;
}

export default Routes;
